import React, {useState, useEffect} from 'react'
import { graphql, StaticQuery } from 'gatsby'
import union from 'lodash/union';
import Accordion from './Accordion'
import Container from './Container'

function Events(props) {
  const { data, filter, set } = props
  const { edges: posts } = data.allMarkdownRemark
  const [themes, setThemes] = useState([])
  const [events, setEvents] = useState([])

  // Get list of active themes
  useEffect(() => {
    posts.map(post => {
      const event = post.node.frontmatter;
      setThemes(themes => union(themes, event.themes))
    });
  },[posts])

  // Seperate events into those themes
  useEffect(() => {
    let sortedEvents = themes.map(theme => {
      let items = {
        button: theme,
        posts: []
      }
      posts
        .filter((post) => post.node.frontmatter.themes.includes(theme))
        .map((post) => (
            items.posts.push(post.node.frontmatter)
          )
        ) 
      return items
      }
    )
    setEvents(sortedEvents)
  }, [posts, themes])

  return (
    <>
      {/* Events is set to only show up if additional events are added. There is a single event called doNotDelete.md this file needs to remain or the static graphql query breaks. This is super important! */}
      {events > 1 ?   
      <Container className="flex flex-col pt-8 pb-12 sm:pt-10 md:pt-12 sm:pb-16 md:pb-20">
        <h2 className="pb-4 text-3xl uppercase font-header text-pink ">
          Upcoming Events
        </h2>
        <Accordion
          accordionitems={events}
          color="purple"
        />
      </Container> 
      : null}
    </>
  )
}


export default props => (
  <StaticQuery
    query={graphql`
      query EventListQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___eventdate] }
          filter: { frontmatter: { templateKey: { eq: "EventTemplate" } } }
        ) {
          edges {
            node {
              id
              frontmatter {
                title
                templateKey
                eventdate
                location
                link
                description
                themes
              }
            }
          }
        }
      }
    `}
    render={data => <Events data={data} {...props} />}
  />
)