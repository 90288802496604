import React  from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Breadcrumb from '../components/Breadcrumb'
//import EventList from '../components/EventList.jsOG'
import Events from '../components/Events.js'
import Banner from '../components/Banner'
import Highlight from '../components/Highlight'
import Container from '../components/Container'
import Section from '../components/Section'

export const ParticipatePageTemplate = ({
  bannerimage,
  title,
  theme,
  section,
  mediasection,
  displayEvents,
}) => {
  // const [visibleAccordionItems, setVisibleAccordionItems] = useState([])
  // This next section is used to replicate the behavior as the CMS generated Accordion, but in a hard-coded fashion so that can pass a child component in with specific props IE an event list as the accordion item body.
  // const accordion = {
  //   accordionitems: [
  //     {
  //       hidden: true,
  //       button: 'An Efficient and Predictable Development Pattern',
  //       body: (
  //           <EventList setVisibleAccordionItems={setVisibleAccordionItems} filter="An Efficient and Predictable Development Pattern" />
  //       ),
  //     },
  //     {
  //       hidden: true,
  //       button: 'A Connected Multimodal Region',
  //       body: <EventList setVisibleAccordionItems={setVisibleAccordionItems} filter="A Connected Multimodal Region" />,
  //     },
  //     {
  //       hidden: false,
  //       button: 'A Safe and Resilient Natural and Built Environment',
  //       body: (
  //         <EventList setVisibleAccordionItems={setVisibleAccordionItems} filter="A Safe and Resilient Natural and Built Environment" />
  //       ),
  //     },
  //     {
  //       hidden: false,
  //       button: 'Healthy, Inclusive and Livable Communities',
  //       body: <EventList setVisibleAccordionItems={setVisibleAccordionItems} filter="Healthy, Inclusive and Livable Communities" />,
  //     },
  //     {
  //       hidden: true,
  //       button: 'A Vibrant Regional Economy',
  //       body: <EventList setVisibleAccordionItems={setVisibleAccordionItems} filter="A Vibrant Regional Economy" />,
  //     },
  //   ],
  //   color: 'purple',
  //   title: 'Upcoming Events',
  // }

  return (
    <>
      <Banner bannerImage={bannerimage} title={title} theme={theme} />
      <Breadcrumb to="participate" label="Participate" />
      <Container className="flex py-10 sm:py-12 md:py-16">
        <Section content={section} />
      </Container>
      <Container
        outerClassName="bg-teal"
        className="py-10 text-white sm:py-12 md:py-16"
      >
        <Highlight highlight={mediasection} />
      </Container>
      {displayEvents ? 
        <Events/>
        : null 
      } 
    </>
  )
}

ParticipatePageTemplate.propTypes = {
  bannerimage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  theme: PropTypes.string,
  section: PropTypes.object,
  mediasection: PropTypes.object,
  displayEvents: PropTypes.any
}

const ParticipatePage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout
      ogtitle={frontmatter.ogtitle}
      ogdescription={frontmatter.ogdescription}
      ogimage={frontmatter.ogimage}
    >
      <ParticipatePageTemplate
        bannerimage={frontmatter.bannerimage}
        title={frontmatter.title}
        theme={frontmatter.theme}
        section={frontmatter.section}
        mediasection={frontmatter.mediasection}
        displayEvents={true}
      />
    </Layout>
  )
}

ParticipatePage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default ParticipatePage

export const pageQuery = graphql`
  query ParticipatePageTemplate($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        opengraph {
          ogtitle
          ogdescription
          ogimage {
            childImageSharp {
              fluid(maxWidth: 300, quality: 80) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        title
        theme
        bannerimage {
          alt
          desktopimage {
            childImageSharp {
              fluid(maxWidth: 1600, quality: 80) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          mobileimage {
            childImageSharp {
              fluid(maxWidth: 833, quality: 80) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        section {
          title
          body
        }
        mediasection {
          title
          body
          highlightsection {
            type
            videoid
            caption
            image {
              childImageSharp {
                fluid(maxWidth: 800, quality: 80) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            slide {
              caption
              alt
              image {
                childImageSharp {
                  fluid(maxWidth: 800, quality: 80) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              markdown
            }
          }
        }
      }
    }
  }
`
